<template>
  <div>
    <div
      v-if="viewAreasPermission"
      :class="{appLoggedOff : isLoggedOff}"
      class="appSectionContainer"
    >
      <div
        class="priorityFilterContainer"
      >
        <div
          :class="getPriorityColour('high')"
          class="priorityIcon"
          @click="applyFilter('high')"
        >
          Sites with Unresolved Alarms {{ `(${getFilteredSiteListForForm('High')})` }}
        </div>
        <div
          :class="getPriorityColour('medium')"
          class="priorityIcon"
          @click="applyFilter('medium')"
        >
          Sites with Alarms Resolved Today {{ `(${getFilteredSiteListForForm('Medium')})` }}
        </div>
        <div
          :class="getPriorityColour('low')"
          class="priorityIcon"
          @click="applyFilter('low')"
        >
          Healthy Sites {{ `(${getFilteredSiteListForForm('Low')})` }}
        </div>
        <div
          :class="getPriorityColour('')"
          class="priorityIcon"
          @click="applyFilter(undefined)"
        >
          All Sites {{ `(${getFilteredSiteListForForm('')})` }}
        </div>
      </div>
      <div class="appSectionBody scSectionBody">
        <div
          :class="{mdHalfScreen : !!selectedSite}"
          class="mdSites"
        >
          <table
            v-if="hasSites"
            id="sitesTable"
          >
            <thead/>
            <tbody
              id="siteHealthTableBody"
            >
              <tr
                v-for="v in currentFilter"
                :key="v.id"
                :class="getRowColour(v.id, v.status)"
                :tabindex="20"
                @click="selectSite(v)"
                @keyup="testSelectSite($event, v)"
              >
                <td :title="v.accountName">{{ v.accountName }}</td>
                <td style="text-align:center;">{{ v.alarms }}</td>
                <td style="text-align:center;">{{ v.tickets }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="!hasSites"
            class="noResults"
          >
            No sites found
          </div>
        </div>
        <div
          v-if="!!selectedSite"
          class="mdSelection"
        >
          <SectionCollapsedHeader
            id="sectionSiteStatus"
            :icon-file="iconFile"
            type="subHeader"
            title="Event Alarms (7 days)"
            controls="siteStatusComponent"
          />
          <SiteStatus
            id="siteStatusComponent"
            :site-rating-markers="siteRatingMarkers"
            :rating-columns="ratingColumns"
            :use-alt-colour="useAltColour"
          />
          <SectionCollapsedHeader
            v-if="viewAreaTicketsPermission"
            id="sectionSiteStatusHistory"
            type="subHeader"
            title="Open Tickets"
            icon-file="Comms2"
            controls="siteStatusHistoryComponent"
          />
          <SiteStatusHistory
            id="siteStatusHistoryComponent"
            :ticket-columns="ticketColumns"
            :visible-tickets="visibleTickets"
            :use-alt-colour="useAltColour"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="noResults"
    >
      Sorry, you do not have permission to view sites.
    </div>
  </div>
</template>

<script>
import SectionCollapsedHeader from '../components/common/sectionCollapsedHeader'
import SiteStatus from '../components/sitesAndCircuits/siteStatus'
import SiteStatusHistory from '../components/sitesAndCircuits/siteStatusHistory'
import { ratingMarkerRepository } from '../communications/repositories/ratingMarkerRepository'
import tableHandler from '../mixins/tableHandler'
import { permissionsMixin } from '../mixins/permissions'
import { ticketMixin } from '../mixins/ticket'
import { mapActions } from 'vuex'
import { DataEventBus } from '../events/dataEvents'

export default {
  metaInfo: {
    meta: [
      { name: 'description', content: 'Maintel iQuote Site Status' }
    ],
    title: 'iQuote - Site Status'
  },
  components: {
    SectionCollapsedHeader,
    SiteStatus,
    SiteStatusHistory
  },
  mixins: [
    tableHandler,
    ticketMixin,
    ratingMarkerRepository,
    permissionsMixin
  ],
  data () {
    return {
      siteIdRating: 0,
      selectedSite: null,
      selected: null,
      ratingColumns: [
        { title: 'Date', extraClass: 'pgTitleClass', width: 'auto' },
        { title: 'Status', extraClass: 'pgCenter', width: '33%' }
      ],
      ticketColumns: [
        { title: 'Title', extraClass: 'pgTitleClass', width: '48%' },
        { title: 'Priority', extraClass: 'pgCenter', width: '15%' },
        { title: 'Date_Added', extraClass: 'pgCenter', width: '20%' },
        { title: 'Status', extraClass: 'pgCenter', width: '17%' }
      ],
      visibleTickets: [],
      visibleSites: [],
      allSites: []
    }
  },
  computed: {
    alarmList () {
      var data = this.$store.getters.ratings
      if (data === null || data.length === 0) {
        return []
      } else {
        var rtn = data
          .sort(({ dateRaised: firstDatRaised }, { dateRaised: secondDateRaised }) => (firstDatRaised < secondDateRaised) ? 1 : -1)
        return rtn
      }
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    viewAreasPermission () {
      return this.$store.getters.viewAreasPermission
    },
    viewAreaTicketsPermission () {
      return this.$store.getters.viewAreaTicketsPermission
    },
    iconFile () {
      // Holder for when we have rating on site
      if (this.selectedSite !== null) {
        return `Health${this.selectedSite.status}`
      } else {
        return 'Health'
      }
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    },
    siteRatingMarkers () {
      if (this.siteIdRating === 0 || !this.ratingMarkers || this.ratingMarkers.length === 0) {
        return []
      }
      return this.ratingMarkers.map((element) => {
        var highlight = this.calculateColour(element.raised, element.rating)
        return {
          Rating: highlight,
          Date: element.dateRaised,
          Status: (element.raised ? 'Raised' : 'Resolved'),
          Reference: element.reference,
          id: element.id
        }
      })
    },
    hasSites () {
      return this.visibleSites.length !== 0
    },
    alarmsFilter () {
      return this.$route.query.alarmsFilter || ''
    },
    currentTickets () {
      return this.ticketList.filter(ticket =>
        ticket.priority !== 'Completed')
    }
  },
  watch: {
    alarmsFilter () {
      this.applyFilter(this.alarmsFilter)
    }
  },
  created () {
    // this.checkPermission()
  },
  mounted () {
    this.checkPermission()
    DataEventBus.$on('refresh-data', () => {
      this.setSites()
    })
    this.initialiseTable(
      'sitesTable',
      [],
      ['accountName', 'alarms', 'tickets'],
      ['Site Name', 'Event Alarms', 'Open Tickets'],
      ['', '', ''],
      null,
      ['85%', '130px', '130px']
    )
    if (this.alarmsFilter.length > 0) {
      this.setVisibleSites(this.alarmsFilter)
    }
    DataEventBus.$emit('still-active')
  },
  methods: {
    ...mapActions([
      'setPage'
    ]),
    selectSite: async function (selectedRecord) {
      DataEventBus.$emit('still-active')
      this.selectedSite = selectedRecord
      if (this.selected === selectedRecord.id) {
        this.selected = '-1'
        this.siteIdRating = 0
        this.siteIdTickets = 0
        this.selectedSite = null
      } else {
        this.selected = selectedRecord.id
        this.siteIdRating = selectedRecord.id
        this.siteIdTickets = selectedRecord.id
        this.getSiteTickets()
        this.ratingMarkersGet(this.$store.getters.username, this.siteIdRating, 7)
      }
    },
    testSelectSite (event, selectedRecord) {
      if (event.keyCode === 32) {
        this.selectSite(selectedRecord)
      }
    },
    getSiteTickets () {
      this.visibleTickets = []
      if (this.siteIdTickets > 0) {
        var returnTickets = []
        this.currentTickets.filter(x => x.accountId === this.siteIdTickets).forEach(element => {
          returnTickets.push(
            {
              id: element.id,
              Title: element.title,
              Date_Added: this.getScreenDate(element.createDateTime),
              Last_Activity: this.getScreenDate(element.lastActivityDateTime),
              Status: this.getStatusFromId(element.statusId),
              Priority: this.getPriorityFromId(element.priorityId)
            }
          )
        })
        this.visibleTickets = returnTickets
      }
    },
    setSites () {
      this.allSites = []
      var sites = this.getFilteredSiteList('')
      if (sites) {
        sites.forEach(element => {
          var site = {
            id: element.id,
            accountName: element.name,
            siteRatingsExist: (element.rating > 0)
          }
          var tempAlarms = this.alarmList
            .filter(x => x.siteId === element.id)
          var tempTickets = this.currentTickets
            .filter(x =>
              x.accountId === element.id)
          site.alarms = tempAlarms.length
          site.tickets = tempTickets.length
          site.status = this.getSitesPriority(tempAlarms)

          this.allSites.push(site)
        })
        this.visibleSites = this.allSites
      }
      this.setTable()
    },
    setVisibleSites (alarmsFilter) {
      this.visibleSites = this.allSites
      if (alarmsFilter !== undefined) {
        switch (alarmsFilter.toLowerCase()) {
          case 'high':
            this.visibleSites = this.allSites.filter(site => site.status === 'Red')
            break
          case 'medium':
            this.visibleSites = this.allSites.filter(site => site.status === 'Amber')
            break
          case 'low':
            this.visibleSites = this.allSites.filter(site => site.status === 'Green')
            break
          default:
            this.visibleSites = this.allSites
            break
        }
      }
      this.setTable()
    },
    getRowColour (id, status) {
      var rtn = ''
      var altColour = ''
      if (!this.useAltColour) {
        altColour = 'Solid'
      }
      switch (status) {
        case 'Green':
          rtn = altColour + 'Green'
          break
        case 'Amber':
          rtn = altColour + 'Amber'
          break
        case 'Red':
          rtn = altColour + 'Red'
          break
        case 'Purple':
          rtn = altColour + 'Purple'
          break
        case 'Grey':
          rtn = altColour + 'Grey'
          break
      }
      if (this.selectSite === id) {
        rtn = 'itemSelected'
      }

      return rtn
    },
    getStatusFromId (statusId) {
      var rtn = this.$store.getters.statusList.find(s => s.value === statusId.toString())
      if (rtn) {
        return rtn.name
      } else {
        return 'Unknown'
      }
    },
    getPriorityFromId (priorityId) {
      var rtn = this.$store.getters.priorityList.find(p => p.value === priorityId.toString())
      if (rtn) {
        return rtn.name
      } else {
        return 'Unknown'
      }
    },
    getScreenDate (dte) {
      var rtn = ''
      if (dte) {
        var date = new Date(dte)
        var day = this.checkForZero(date.getDate())
        var year = this.checkForZero(date.getFullYear())
        var month = this.checkForZero(date.getMonth() + 1)
        var hours = this.checkForZero(date.getHours())
        var minutes = this.checkForZero(date.getMinutes())
        rtn = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
      }
      return rtn
    },
    calculateColour (raised, rating) {
      if (raised) {
        if (rating === 4) {
          return 'Purple'
        } else if (rating === 3) {
          return 'Red'
        } else if (rating === 2) {
          return 'Amber'
        } else if (rating === 1) {
          return 'Green'
        }
      }
      return 'Grey'
    },
    setTable () {
      if (this.hasSites) {
        this.$nextTick(() => {
          this.initialiseTable(
            'sitesTable',
            this.visibleSites,
            ['accountName', 'alarms', 'tickets'],
            ['Site Name', 'Event Alarms', 'Open Tickets'],
            ['', '', ''],
            null,
            ['85%', '130px', '130px']
          )
        })
      }
    },
    checkPermission: async function () {
      this.setPage('Sites')
      if (this.viewAreasPermission) {
        this.setSites()
      } else {
        this.setPage('Login')
      }
    },
    getPriorityColour: function (priority) {
      var isSelectedPriority = this.alarmsFilter === priority ? 'selected' : ''

      switch (priority ? priority.toLowerCase() : '') {
        case 'high':
          return 'Red ' + isSelectedPriority
        case 'medium':
          return 'Amber ' + isSelectedPriority
        case 'low':
          return 'Green ' + isSelectedPriority
        default:
          return 'Grey ' + isSelectedPriority
      }
    },
    applyFilter: function (alarmsFilter) {
      this.$router.push({ path: 'site-health', query: { alarmsFilter } })
      // Clear the selected site
      this.selectedSite = null
      // recalculate the sites to display
      this.setVisibleSites(alarmsFilter)
    },
    getFilteredSiteListForForm: function (filter = '') {
      var rtn = 0
      switch (filter.toLowerCase()) {
        case 'high':
          rtn = this.allSites.filter(site => site.status === 'Red').length
          break
        case 'medium':
          rtn = this.allSites.filter(site => site.status === 'Amber').length
          break
        case 'low':
          rtn = this.allSites.filter(site => site.status === 'Green').length
          break
        default:
          rtn = this.allSites.length
          break
      }
      return rtn
    },
    getFilteredSiteList: function (filter = '') {
      var filteredAlarmList = this.getFilteredAlarmList(filter)
      var sites = this.$store.getters.sites
      var rtn
      switch (filter.toLowerCase()) {
        case 'high':
          rtn = sites.filter(site =>
            filteredAlarmList.some(({ siteId }) => siteId === site.id))
          break
        case 'medium':
          rtn = sites.filter(site =>
            filteredAlarmList.some(({ siteId }) => siteId === site.id))
          break
        case 'low':
          var highPriorityAlarms = this.getFilteredAlarmList('High')
          var mediumPriorityAlarms = this.getFilteredAlarmList('Medium')
          rtn = sites.filter(site =>
            !highPriorityAlarms.some(alarm => alarm.siteId === site.id) &&
            !mediumPriorityAlarms.some(alarm => alarm.siteId === site.id))
          break
        default:
          rtn = sites
          break
      }
      return rtn
    },
    getFilteredAlarmList: function (filter) {
      var highPriorityAlarms = this.alarmList.filter(alarm =>
        alarm.raised &&
        !this.alarmList.filter(alarm2 => !alarm2.raised && alarm2.reference === alarm.reference && new Date(alarm2.dateRaised) >= new Date(alarm.dateRaised)).length)
      switch (filter ? filter.toLowerCase() : '') {
        case 'high':
          return highPriorityAlarms
        case 'medium':
          return this.alarmList.filter(alarm =>
            alarm.raised &&
            new Date(alarm.dateRaised) >= new Date(new Date().setHours(0, 0, 0, 0)) &&
            !highPriorityAlarms.some(({ siteId }) => siteId === alarm.siteId))
        default:
          return this.alarmList
      }
    },
    getSitesPriority: function (alarmsList) {
      var status = ''

      // if there are alarms raised today which haven't been closed
      if (alarmsList.some(
        alarm => alarm.raised &&
        !alarmsList.some(alarm2 => !alarm2.raised && alarm2.reference === alarm.reference && new Date(alarm2.dateRaised) >= new Date(alarm.dateRaised))
      )) {
        status = 'Red'
      // if there all alarms have been raised and closed in the same day
      } else if (alarmsList.some(alarm => !alarm.raised && new Date(alarm.dateRaised) >= new Date(new Date().setHours(0, 0, 0, 0)))) {
        status = 'Amber'
      // there are no alarms raised
      } else if (!alarmsList.length || !alarmsList.some(alarm => new Date(alarm.dateRaised) >= new Date())) {
        status = 'Green'
      }
      return status
    },
    checkForZero (int) {
      if (int.toString().length === 1) {
        int = '0' + int
      }
      return int
    }
  }
}
</script>

<style scoped>
  .appSectionBody {
    margin-bottom: 40px;
  }

  .Red {
    background-color: rgb(239, 83, 80);
    border: 2px rgb(240, 73, 70) solid;
  }

  .Amber {
    background-color: rgb(255, 167, 38);
    border: 2px rgb(241, 159, 36) solid;
  }

  .Green {
    background-color: rgb(102, 187, 106);
    border: 2px rgb(93, 170, 97) solid;
  }

  .Grey {
    background-color: rgb(214, 213, 213);
    border: 2px rgb(199, 198, 198) solid;
  }

  .Purple {
    background-color: rgb(167, 80, 239);
    border: 2px rgb(155, 48, 243) solid;
  }

  .priorityFilterContainer {
    display: flex;
  }

  .priorityIcon {
    flex: 1;
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    font-size: 0.86em;
    cursor: pointer;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    /* text-shadow: 2px 2px 2px rgb(156 147 147 / 87%); */

  }

  .selected {
    color: #fff;
  }
</style>
